export const EVENT_NAMES = {
  HERO_BANNER_SHOP_BUTTON: 'Cx_HeroBanner_Shop_Click',
  COLLECTION_WIDGET_CARD_CLICK: 'Cx_Collection_Widget_Card_Click',
};

export const FORM_TYPE = {
  BRAND_THEME: 2,
  NON_BRAND_THEME: 1,
};

export const canUseOTPLessLogin = true;

export const TRIGGERED_PROMO_STATUSES = {
  PROMO_X_NOT_SATISFIED: 0,
  PROMO_X_SATISFIED: 1,
  PROMO_Y_SATISFIED: 2,
  PROMO_X_AND_Y_SATISFIED: 3,
};
