/* eslint prefer-promise-reject-errors: 0 */ // --> ON

let OTPlessSignin = null;

export const COUNTRY_CODE = {
  INDIA: '+91',
} as const;

const CHANNELS = {
  PHONE: 'PHONE',
  EMAIL: 'EMAIL',
  OAUTH: 'OAUTH',
} as const;

export const RESPONSE_STATUS = {
  SUCCESS: {
    CODE: 200,
  },
  ERROR: {
    CODE: 400,
    MESSAGE: {
      PHONE: 'Phone is required',
      EMAIL: 'Email is required',
      CHANNEL: 'Channel type is required',
      INVALID_CHANNEL: 'Invalid channel is required',
    },
  },
};
export const OTP_RESPONSE_TYPE = {
  INITIATE: 'INITIATE',
  OTP_AUTO_READ: 'OTP_AUTO_READ',
  VERIFY: 'VERIFY',
  ONETAP: 'ONETAP',
};
/**
 * Creates a script element to load the OTPless SDK.
 *
 * @return {void} No return value
 */

async function loadOTPlessSdk(callback?: any) {
  return new Promise((resolve) => {
    if (document.getElementById('otpless-sdk') && OTPlessSignin)
      return resolve(OTPlessSignin);
    const script = document.createElement('script');
    script.src = 'https://otpless.com/v3/headless.js';
    script.id = 'otpless-sdk';
    script.setAttribute('data-appid', process.env.NEXT_PUBLIC_OTPLESS_APP_ID);

    script.onload = function () {
      const OTPless = Reflect.get(window, 'OTPless');
      OTPlessSignin = new OTPless(callback);
      resolve(OTPlessSignin);
    };

    document.head.appendChild(script);
  });
}

/**
 * Initializes the OTPless SDK and sets up a callback function.
 *
 * @param {function} callback - The callback function to be executed after successful authentication.
 * @return {void} No return value.
 */
export const initOTPless = async (callback) => {
  // Loading the script if it's not already loaded
  await loadOTPlessSdk(callback);
};

export const removeOTPLessSDK = () => {
  document.getElementById('otpless-sdk')?.remove();
  OTPlessSignin = null;
  window.OTPless = undefined;
};

async function hitOTPlessSdk(params) {
  await loadOTPlessSdk();

  const { requestType, request } = params;
  const { channel, email, phone, channelType } = request;

  if (
    channel != CHANNELS.OAUTH &&
    channel != CHANNELS.EMAIL &&
    channel != CHANNELS.PHONE
  ) {
    return Promise.reject({
      success: false,
      statusCode: RESPONSE_STATUS.ERROR.CODE,
      errorMessage: RESPONSE_STATUS.ERROR.MESSAGE.INVALID_CHANNEL + ` ${channel}`,
    });
  }
  if (channel === CHANNELS.EMAIL && !email) {
    return Promise.reject({
      success: false,
      statusCode: RESPONSE_STATUS.ERROR.CODE,
      errorMessage: RESPONSE_STATUS.ERROR.MESSAGE.EMAIL,
    });
  }
  if (channel === CHANNELS.PHONE && !phone) {
    return Promise.reject({
      success: false,
      statusCode: RESPONSE_STATUS.ERROR.CODE,
      errorMessage: RESPONSE_STATUS.ERROR.MESSAGE.PHONE,
    });
  }
  if (channel === CHANNELS.OAUTH && !channelType) {
    return Promise.reject({
      success: false,
      statusCode: RESPONSE_STATUS.ERROR.CODE,
      errorMessage: RESPONSE_STATUS.ERROR.MESSAGE.CHANNEL,
    });
  }

  return await OTPlessSignin[requestType](request);
}

export interface IINIT_OTP_SEND {
  channel?: keyof typeof CHANNELS;
  channelType?: keyof typeof CHANNELS;
  phone?: string;
  countryCode?: string;
  email?: string;
}
/**
 * Authenticates the user using any authentication method available.
 * Email / Phone, OTP / Magic Link / Social Authentications
 * @param {Object} params - The parameters for primary authentication.
 * for social authentication use 'channel': 'OAUTH' and 'channelType' (eg. 'GOOGLE', 'WHATSAPP', 'GITHUB', etc)
 * for otp/magic link via email use 'channel': 'EMAIL' and 'email'
 * for otp/magic link via phone use 'channel': 'PHONE', 'phone' and 'countryCode'(optional)
 * @TODO activate your chosen authentication method from otpless Dashboard(https://otpless.com/dashboard/customer/channels) before executing this function
 * */
export const initiateOTPSend = async (params: IINIT_OTP_SEND) => {
  const {
    channel = CHANNELS.PHONE,
    channelType = CHANNELS.PHONE,
    phone = '',
    countryCode = COUNTRY_CODE.INDIA,
    email = '',
  } = params;

  return await hitOTPlessSdk({
    requestType: 'initiate',
    request: {
      channel,
      channelType,
      phone,
      countryCode,
      email,
    },
  });
};

export type IINIT_VERIFY_OTP = IINIT_OTP_SEND & {
  otp: string;
};
/**
 * Verifies the OTP (One-Time Password) for the given authentication channel.
 *
 * @param {Object} params - The parameters for verifying the OTP.
 * @param {string} [channel='PHONE'] - The authentication channel (default: 'PHONE').
 * @param {string} otp - The OTP to be verified.
 * @param {string} [countryCode='+91'] - The country code for the user's phone number (default: '+91').
 * @param {string} phone - The user's phone number.
 * @param {string} email - The user's email address.
 * @return {Promise} A promise that resolves with the result of the verification.
 */
export const verifyOTPLess = async (params: IINIT_VERIFY_OTP) => {
  const {
    channel = CHANNELS.PHONE,
    otp,
    countryCode = COUNTRY_CODE.INDIA,
    phone,
    email,
  } = params;
  return await hitOTPlessSdk({
    requestType: 'verify',
    request: {
      channel,
      phone,
      countryCode,
      email,
      otp,
    },
  });
};
