import {
  generateOTP,
  generateOTPforEmail,
  generateOTPMerchants,
  verifyMerchantActionOtp,
  verifyOTP,
  verifyOTPforEmail,
} from '@/redux/actions';
import { MAX_MOBILE_WIDTH } from '@/utils/constants';
import { deviceWidth } from '@/utils/deviceWidth';
import router from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  initiateOTPSend,
  initOTPless,
  verifyOTPLess,
  IINIT_OTP_SEND,
  IINIT_VERIFY_OTP,
  OTP_RESPONSE_TYPE,
  RESPONSE_STATUS,
  COUNTRY_CODE,
  removeOTPLessSDK,
} from './useOtpLoginUtils';
import { toastNotifyError, toastNotifySuccess } from 'src/components/Common/Toast';
import { canUseOTPLessLogin } from 'src/constants/common';
import { useWidgetDndContextData } from 'src/context/WidgetDndContext';
import { RootState } from 'src/redux/reducers';
import { getDecryptedContactInfo } from 'src/utils/getDecryptedContactInfo';

let verifyOTPConfigs: any = {};

const useAuthenticate = (timeLimit, isLoggedIn = false, merchantAuthFlow = false) => {
  const { authToken } = useWidgetDndContextData();
  const ownerPhone = useSelector(
    (state: RootState) => state.storeReducer?.store?.owner?.phone
  );
  const dispatch = useDispatch();
  const [otp, setOtp] = useState('');
  const [timeLeft, setTimeLeft] = useState(timeLimit);
  const [invalidOtp, setInvalidOtp] = useState(false);
  const [isOtpSent, setIsOtpSent] = useState(false);

  const {
    userData: { email = '', phone = '' },
    storeInfo,
  } = useSelector((state: any) => ({
    userData: state.userReducer?.data || {},
    storeInfo: state.storeReducer.store || {},
  }));

  const timerId = useRef(null);

  useEffect(() => {
    if (merchantAuthFlow) return;

    // isUserCallbackExecuted is used as "ONETAP" event is fired multiple times in low network conditions (replicable on cart page)
    let isUserCallbackExecuted = false;
    if (canUseOTPLessLogin) {
      initOTPless(function onOTPVerificationComplete(userData) {
        if (
          userData?.responseType !== OTP_RESPONSE_TYPE.ONETAP ||
          isUserCallbackExecuted
        ) {
          return;
        }
        isUserCallbackExecuted = true;

        const { callback, isLoginAttempt, isRedirection, isEmailLogin, contact } =
          verifyOTPConfigs;
        const payload = {
          token: userData?.response?.token,
          source: 'cfe',
          [isEmailLogin ? 'email' : 'phone']: contact,
          clevertapEventData: getClevertapEventData(isEmailLogin, contact),
        };
        dispatch(
          verifyOTP(payload, router, isRedirection, callback, isLoginAttempt, false)
        );
      });
      return () => {
        removeOTPLessSDK();
        verifyOTPConfigs = {};
      };
    }
  }, []);

  useEffect(() => {
    generateOtp();
  }, [isLoggedIn, merchantAuthFlow]);

  useEffect(() => {
    if (!timeLeft) {
      endTimer();
    }
  }, [timeLeft]);

  function generateOtp() {
    if (!merchantAuthFlow) {
      if (isLoggedIn) {
        sendOtp();
      }
    } else {
      sendOtpMerchant();
    }
  }

  function getClevertapEventData(isEmailLogin, contact) {
    return {
      event_name: isLoggedIn ? 'Cx_Delete' : 'Cx_Login',
      data: {
        store_id: storeInfo?.store_id,
        domain: storeInfo?.store_info?.domain,
        page: window.location.href,
        device: deviceWidth <= MAX_MOBILE_WIDTH ? 'Mobile' : 'Desktop',
        [isEmailLogin ? 'cx_email' : 'cx_phone']: contact,
      },
    };
  }

  function startTimer() {
    setTimeLeft(timeLimit);
    endTimer();
    timerId.current = setInterval(() => setTimeLeft((prevTime) => --prevTime), 1000);
  }

  function endTimer() {
    if (timerId.current) {
      clearInterval(timerId.current);
      timerId.current = null;
    }
  }

  function onOtpChange(e) {
    if (invalidOtp) {
      setInvalidOtp(false);
    }
    const value = e.target.value.trim();
    !isNaN(value) && value.length <= 4 && setOtp(value);
  }

  function onSendOtpSuccess() {
    setIsOtpSent(true);
    startTimer();
  }

  function sendOtpMerchant() {
    dispatch(generateOTPMerchants(authToken, onSendOtpSuccess));
  }

  async function sendOtp(
    contact = email || phone || '',
    isEmailLogin = !!email,
    configArgs = null
  ) {
    if (!canUseOTPLessLogin) {
      const data = {
        email: contact,
        store_id: storeInfo?.store_id,
      };

      dispatch(
        isEmailLogin
          ? generateOTPforEmail(data, router, 'web', null, onSendOtpSuccess)
          : generateOTP(contact, router, 'web', null, onSendOtpSuccess)
      );
    } else {
      if (configArgs) {
        // These are required because the email already logged in the browser, gets directly to auto-verify state & executes the
        // onOTPVerificationComplete method, which requires these configs
        const [callback, isLoginAttempt, contact, isEmailLogin, isRedirection] =
          configArgs;
        verifyOTPConfigs = {
          callback,
          isLoginAttempt,
          contact,
          isEmailLogin,
          isRedirection,
        };
      }
      const payload: IINIT_OTP_SEND = {
        [isEmailLogin ? 'email' : 'phone']: contact,
        channel: isEmailLogin ? 'EMAIL' : 'PHONE',
        channelType: isEmailLogin ? 'EMAIL' : 'PHONE',
        countryCode: isEmailLogin ? '' : COUNTRY_CODE.INDIA,
      };
      initiateOTPSend(payload)
        ?.then((data) => {
          console.log('data:  ', data);
          if (data.responseType !== OTP_RESPONSE_TYPE.INITIATE) {
            return;
          }
          if (data.statusCode !== RESPONSE_STATUS.SUCCESS.CODE) {
            toastNotifyError(
              data.errorMessage || data?.response?.errorMessage || 'Please try again!'
            );
            return;
          }
          toastNotifySuccess('OTP sent Successfully');
          onSendOtpSuccess();
        })
        .catch((response) => toastNotifyError(response.errorMessage));
    }
  }

  function verifyMerchantActionByOtp(callback) {
    const payload = {
      otp: +otp,
      phone: getDecryptedContactInfo(ownerPhone),
    };
    dispatch(verifyMerchantActionOtp(payload, callback));
  }

  async function verifyOtp(
    callback,
    isLoginAttempt = false,
    contact = email || phone || '',
    isEmailLogin = !!email,
    isRedirection = false
  ) {
    if (!canUseOTPLessLogin) {
      const payload = {
        otp: otp,
        [isEmailLogin ? 'email' : 'phone']: contact,
        clevertapEventData: getClevertapEventData(isEmailLogin, contact),
      };
      const verifyOtpFinal = isEmailLogin ? verifyOTPforEmail : verifyOTP;
      dispatch(verifyOtpFinal(payload, router, isRedirection, callback, isLoginAttempt));
    } else {
      verifyOTPConfigs = {
        callback,
        isLoginAttempt,
        contact,
        isEmailLogin,
        isRedirection,
      };
      const payload: IINIT_VERIFY_OTP = {
        otp: otp,
        [isEmailLogin ? 'email' : 'phone']: contact,
        channel: isEmailLogin ? 'EMAIL' : 'PHONE',
      };
      verifyOTPLess(payload)
        ?.then((response) => {
          if (response?.statusCode !== RESPONSE_STATUS.SUCCESS.CODE) {
            callback?.(false);
            toastNotifyError(
              response?.errorMessage ||
                response?.response?.errorMessage ||
                'We ran into some issue. Please try again.'
            );
          }
        })
        .catch((response) => toastNotifyError(response.errorMessage));
    }
  }

  return {
    setOtp,
    otp,
    isOtpSent,
    invalidOtp,
    timeLeft,
    generateOtp,
    setIsOtpSent,
    setTimeLeft,
    setInvalidOtp,
    onOtpChange,
    sendOtp,
    startTimer,
    endTimer,
    verifyOtp,
    verifyMerchantActionByOtp,
  };
};

export default useAuthenticate;
